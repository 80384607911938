import {BrowserRouter as Router, Routes, Route, Navigate} from "react-router-dom";
import HomeThreeMain from '../components/home-three/HomeThreeMain';
import HeaderThree from '../layout/headers/header-three';
import FooterThree from '../layout/footers/footer-three';
import AboutMain from "../components/about/AboutMain";
import ContactMain from "../components/contact/ContactMain";
import BlogGridMain from "../components/blog-grid/BlogGridMain";
import BlogDetailsMain from "../components/blog-details/BlogDetailsMain";
import ShopMain from "../components/shop/ShopMain";
import ShopDetailsMain from "../components/shop-details/ShopDetailsMain";
import LoginMain from "../components/login/LoginMain";
import CartMain from "../components/cart/CartMain";
import CheckOutMain from "../components/checkout/CheckOutMain";
import UserProfileMain from '../components/profile/studentProfile/UserProfileMain';
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";

export default function AppRoutes(){
    const userData = useSelector(
      (state: RootState) => state.user.user
    );
    return(
        <Router>
                <HeaderThree/>
                    <Routes>

                            {(true) && <Route  path='/' element={<HomeThreeMain />}/>}
                            {(true) && <Route  path='/about' element={<AboutMain />}/>}
                            {(true) && <Route  path='/contact' element={<ContactMain />}/>}
                            {(true) && <Route  path='/blog' element={<BlogGridMain />}/>}
                            {(true) && <Route  path='/blog-details/:id' element={<BlogDetailsMain />}/>}
                            {(true) && <Route  path='/shop/:id?' element={<ShopMain />}/>}
                            {(true) && <Route  path='/shop-details/:id' element={<ShopDetailsMain />}/>}
                            
                            {(true) && <Route  path='/login' element={<LoginMain />}/>}

                            {(true) && <Route  path='/cart' element={<CartMain />}/>}
                            {(userData) && <Route  path='/checkout' element={<CheckOutMain />}/>}
                            {(!userData) && (
                                <Route  path='/checkout' element={<Navigate to="/login" replace />} />
                            )}

                            {(userData) && <Route  path='/profile/:slug?/:id?' element={<UserProfileMain />}/>}
                            {(!userData) && (
                                <Route  path='/profile/:slug?/:id?' element={<Navigate to="/login" replace />} />
                            )}

                    </Routes>
                <FooterThree/>
        </Router>
    )
}
