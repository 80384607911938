"use client";
import React, { useEffect, useState } from "react";
import useGlobalContext from "../../../hooks/use-context";
import { Link } from 'react-router-dom';
import { cart_product } from "../../../redux/slices/cartSlice";
import { CartProductTypeTwo } from "../../../interFace/interFace";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { wishlist_product } from "../../../redux/slices/wishlistSlice";
import { getProductsList } from '../../../utils/ApiCalls';
import { useAppDispatch } from '../../../hooks/store-hooks';
import { constants } from "../../../utils/constants";
import { toast } from "react-toastify";

const BrowseProductSlider = () => {
  const language = useSelector(
    (state: RootState) => state.user.language
  );
  const user = useSelector(
    (state: RootState) => state.user.user
  );

  const translations = useSelector((state: RootState) => state.user.translations);

  const { setOpenModal, openModal, setModalId } = useGlobalContext();
  const dispatch = useAppDispatch();
  const [products, setProducts] = useState<[]>([]);

  const handleMoldalData = (id: string) => {
    if (id) {
      setOpenModal(!openModal);
      setModalId(id);
    }
  };


  useEffect(() => {
    getProductsList(1, 8).then(response => {
      setProducts(response.data.data)
    }).catch(error => {
      console.log(error)
    });
  }, []);

  const handleAddToCart = (product: CartProductTypeTwo) => {
    if(user) dispatch(cart_product(product))
    else toast.error('Please Login First !')
  };

  const handleWishlistProduct = (item: any) => {
    if(user) dispatch(wishlist_product(item))
    else toast.error('Please Login First !')
  }
  return (
    <>
      <div className="row">
        <div className="col-xxl-4 col-xl-5 col-lg-4">
          <div className="bd-section__title-wrapper mb-40">
            <div className="bd-sm__section-title">
              <h3> {translations?.nav?.producttop}</h3>
            </div>
          </div>
        </div>

        <div className="col-12">
          <div className="tab-content">
            <div className="tab-pane fade show active">
              <div className="bd-trending__item-wrapper mb-15">
                <div className="row">
                  {products?.length ? (
                    <>
                     <>
                          {products?.length &&
                            products?.map((item: any, index) => {
                             
                              return (
                                <div
                                  className="col-xxl-3 col-xl-6 col-lg-4 col-md-6 col-sm-6"
                                  key={index}
                                >
                                  <div className="bd-trending__item text-center mb-30 position-relative">
                                      <div className="bd-trending__product-thumb">
                                        <Link to={`/shop-details/${item?.id}/`}>
                                          <img
                                            width={500}
                                            height={500}
                                            style={{
                                              width: "100%",
                                              height: "auto",
                                            }}
                                            src={item?.photo}
                                            alt="product-img"
                                          />
                                        </Link>
                                        <div className="bd-product__action">
                                          <span
                                            className="cart-btn"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Quick Shop"
                                            onClick={() =>
                                              handleAddToCart(item)
                                            }
                                          >
                                            <i className="fal fa-cart-arrow-down"></i>
                                          </span>
                                          <span
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Quick View"
                                            data-bs-toggle="modal"
                                            data-bs-target="#productmodal"
                                            onClick={() =>
                                              handleMoldalData(item?.id)
                                            }
                                          >
                                            <i className="fal fa-eye"></i>
                                          </span>
                                          <span
                                            className="wishlist-btn"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Quick Wishlist"
                                            onClick={() =>
                                              handleWishlistProduct(item)
                                            }
                                          >
                                            <i className="fal fa-heart"></i>
                                          </span>
                                        </div>
                                      </div>
                                      <div className="bd-teanding__content">
                                        <h4 className="bd-product__title">
                                          <Link to={`/shop-details/${item?.id}/`}>
                                            {item?.translations[language]?.name}
                                          </Link>
                                        </h4>
                                        <div className="bd-product__price">
                                          {item?.offer ? (
                                            <span className="bd-product__old-price">
                                              <del>
                                                {`${
                                                  item?.offer?.price % 1 === 0
                                                    ? `${item?.offer?.price}.00`
                                                    : item?.price.toFixed(2)
                                                }`}  {constants.currency}
                                              </del>
                                            </span>
                                          ) : (
                                            <></>
                                          )}

                                          {item?.price % 1 === 0 ? (
                                            <span className="bd-product__new-price">
                                              {`${item?.price}.00`} {constants.currency}
                                            </span>
                                          ) : (
                                            <span className="bd-product__new-price">
                                              {item?.price.toFixed(2)} {constants.currency}
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                      <div className="bd-product__tag">
                                        {item?.offer ? (
                                          <>
                                            <span className="tag-text danger-bg">
                                              {" "}
                                              {item.offerPersent}%
                                            </span>
                                          </>
                                        ) :
                                        ( 
                                            item?.featured == 1 ? (
                                              <>
                                                <span className="tag-text theme-bg">
                                                  {" "}
                                                  {translations?.nav?.featured}
                                                </span>
                                              </>
                                            ) : ""
                                        )}
                                      </div>
                                  </div>
                                </div>
                              );
                            })}
                        </>
                    </>
                  ) : (
                    <>
                     
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </>
  );
};

export default BrowseProductSlider;
